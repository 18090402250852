<template>
  <div class="container">
    <template v-if="RetMsg">
      <img class="bugIcon" src="@/assets/images/bug.png" />
      <p class="errorMessage">{{RetMsg}}</p>
    </template>
  </div>
</template>

<script>
import storage from "../../common/utils/storage";
import { mapState } from 'vuex'

export default {
  name: 'JNJUser',
  data() {
    return {
      RetMsg: ''
    }
  },
  computed: {
    ...mapState(['Openid']),
    code() {
      return this.$route.query.code;
    },
  },
  created() {
    this.$toast.loading({
      message: '正在跳转中',
      forbidClick: true,
      loadingType: 'spinner',
      duration: 0
    });
    if (this.code) {
      let JNJUserSource = sessionStorage.getItem("JNJUserSource");
      if(JNJUserSource == 'h5api') {
        this.$axios.post('/h5api/Api/Web/GetJNJUserByWeb/GetUser?OpenID=' + this.Openid + '&code=' + this.code).then(res => {
          this.$toast.clear();
          if (res.RetCode == '10000') {
            this.$router.push('/electronicPhoto');
          } else {
            if(res.RetMsg == '员工不存在') {
              this.RetMsg = '您暂无权限访问该页面';
            } else {
              this.RetMsg = res.RetMsg;
            }
          }
        })
      } else {
        this.$axios.post('/Api/Api/Web/GetJNJUserByWeb/GetUser?OpenID=' + this.Openid + '&code=' + this.code).then(res => {
          if (res.RetCode == '10000') {
            this.$toast.clear();
            storage.setStore('Token', res.Data.Token);
            this.$router.push('/interest');
          } else {
            // this.$toast(res.RetMsg);
            this.RetMsg = res.RetMsg;
          }
        })
      }
    } else {
      let chars = 'ABCDEFGHJKMNPQRSTWXYZabcdefhijkmnprstwxyz2345678';
      let maxPos = chars.length;
      let nonce = '';
      for (let i = 0; i < 6; i++) {
        nonce += chars.charAt(Math.floor(Math.random() * maxPos));
      }
      window.location.href = `https://fedlogin.jnj.com/as/authorization.oauth2?client_id=WCWECHAT&response_type=code&redirect_uri=${process.env.VUE_APP_BASE_URL}/JNJUser&scope=address%20email%20openid%20phone%20profile&nonce=${nonce}`
    }
  }
}
</script>

<style lang="scss" scoped>
.container {
  padding: 20px;
  box-sizing: border-box;
}
.bugIcon {
  display: block;
  width: 120px;
  margin: 100px auto 20px;
}
.errorMessage {
  font-size: 14px;
  display: block;
  text-align: center;
}
</style>